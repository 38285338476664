import { WithLocalStorage, withLocalStorage } from '@blackbird/ui-base/hooks';
import { Checkbox, Divider, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Tooltip } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { TFunction } from 'i18next';
import { ViewQuilt } from 'mdi-material-ui';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { compose } from 'recompose';

import CountdownButton from '@/components/buttons/countdown-button';
import * as Constants from '@/constants';
import { WithStyles, withStyles } from '@/hocs/with-styles';

const styles = (theme: Theme) => ({
  betaContainer: {
    position: 'absolute',
    top: '7px',
    right: '2px',
  },
  betaText: {
    fontSize: '0.6rem',
  },
  menuItemTitle: {
    color: theme.palette.text.primary,
  },
  menuItemStyles: {
    padding: theme.spacing(0),
  },
});

export type TimeInterval = 0.5 | 4 | 8 | 16 | 24 | 48 | 72;

export interface CardConfiguration {
  showGraph: boolean;
  showLineStatus: boolean;
  showBatchProgress: boolean;
  showBatchProgressPercentage: boolean;
  showEstimatedTimeOfCompletion: boolean;
  // showProducedToday: boolean;
  showProduced: boolean;
  showBatchProductNumber: boolean;
  showBatchProduct: boolean;
  showBatchNumber: boolean;
  timeInterval: TimeInterval;
  refreshInterval: number | null;
  showStops: boolean;
  showStopLabels: boolean;
  showOee: boolean;
  countUnregisteredStops: boolean;
  oneLineCardEachRow: boolean;
  unresolvedAndonCalls: boolean;
  showWeeklyProgress: boolean;
  showDailyProgress: boolean;
}

export const instanceOfCardConf = (object: object) => {
  return (
    'showGraph' in object &&
    'showBatchProduct' in object &&
    'showLineStatus' in object &&
    'showBatchProductNumber' in object &&
    'showBatchProgress' in object
  );
};

export const defaultCardConf: CardConfiguration = {
  showGraph: true,
  showLineStatus: false,
  showBatchProgress: false,
  showBatchProgressPercentage: false,
  showBatchProduct: false,
  showBatchProductNumber: false,
  showEstimatedTimeOfCompletion: false,
  showProduced: false,
  showBatchNumber: false,
  timeInterval: 8,
  refreshInterval: (5).minutes,
  showStops: false,
  showStopLabels: false,
  showOee: false,
  countUnregisteredStops: false,
  oneLineCardEachRow: false,
  unresolvedAndonCalls: false,
  showWeeklyProgress: false,
  showDailyProgress: false,
};

const mkTimeIntervalTranslation = (t: TFunction, interval: TimeInterval) => {
  if (interval === 0.5) {
    return t(['line:nMinutesTimeInterval'], { defaultValue: '{{minutes}} minutes time interval', minutes: 30 });
  }
  return t(['line:nHourTimeInterval'], { defaultValue: '{{hour}} hour time interval', hour: interval });
};

interface TimeIntervalMenuItemProperties {
  t: TFunction;
  interval: TimeInterval;
  cardConf: CardConfiguration;
  handleCheck: (interval: TimeInterval) => () => void;
  classes: any;
}

const TimeIntervalMenuItem = (props: TimeIntervalMenuItemProperties) => {
  const { t, interval, cardConf, handleCheck, classes } = props;
  return (
    <MenuItem onClick={handleCheck(interval)} className={classes.menuItemStyles}>
      <ListItemIcon>
        <Checkbox
          color="primary"
          checked={cardConf && cardConf.timeInterval === interval ? true : false}
          onChange={handleCheck(interval)}
          tabIndex={-1}
          disableRipple
        />
      </ListItemIcon>
      <ListItemText primary={mkTimeIntervalTranslation(t, interval)} />
    </MenuItem>
  );
};

interface RefreshIntervalMenuItemProperties {
  label: string;
  interval: number | null;
  cardConf: CardConfiguration;
  handleCheck: (interval: number | null) => () => void;
  classes: any;
}

const RefreshIntervalMenuItem = (props: RefreshIntervalMenuItemProperties) => {
  const { label, interval, cardConf, handleCheck, classes } = props;
  return (
    <MenuItem onClick={handleCheck(interval)} className={classes.menuItemStyles}>
      <ListItemIcon>
        <Checkbox
          color="primary"
          checked={cardConf && cardConf.refreshInterval === interval ? true : false}
          onChange={handleCheck(interval)}
          tabIndex={-1}
          disableRipple
        />
      </ListItemIcon>
      <ListItemText primary={label} />
    </MenuItem>
  );
};

interface Properties {
  updateInterval: number | null;
  nextLiveUpdate: Date | null;
  cardConf: CardConfiguration;
  updateConf: (key: string, check: boolean) => void;
  updateIntervalConf: (interval: TimeInterval) => void;
  updateRefreshIntervalConf: (newInterval: number | null) => void;
}

interface State {
  menuAnchorEl: HTMLElement | null;
}

interface ExtendedProperties extends Properties, WithStyles<typeof styles>, WithTranslation, WithLocalStorage {}
class ConfigurationMenu extends React.Component<ExtendedProperties, State> {
  readonly state: State = {
    menuAnchorEl: null,
  };

  shouldComponentUpdate(nextProps: ExtendedProperties, nextState: State) {
    if (
      nextProps.cardConf !== this.props.cardConf ||
      nextState.menuAnchorEl !== this.state.menuAnchorEl ||
      nextProps.updateInterval !== this.props.updateInterval ||
      nextProps.nextLiveUpdate !== this.props.nextLiveUpdate
    ) {
      return true;
    }
    return false;
  }

  render() {
    const { classes, t, cardConf, updateInterval, nextLiveUpdate } = this.props;
    const open = Boolean(this.state.menuAnchorEl);
    return (
      <>
        <CountdownButton updateInterval={updateInterval} nextLiveUpdate={nextLiveUpdate}>
          <Tooltip
            title={t(['shared:configurePersonalOverview'], { defaultValue: 'Configure your personal overview' })}
            placement={'left-start'}
          >
            <IconButton
              aria-label="More"
              aria-owns={false ? 'long-menu' : undefined}
              aria-haspopup="true"
              onClick={this.handleMenuClick}
              size="large"
            >
              <ViewQuilt />
            </IconButton>
          </Tooltip>
        </CountdownButton>
        <Menu
          id="configuration-menu"
          anchorEl={this.state.menuAnchorEl}
          open={open}
          keepMounted
          onClose={this.handleMenuClose}
        >
          <MenuItem dense disabled>
            <ListItemText
              classes={{ secondary: classes.menuItemTitle }}
              secondary={t(['line:showOrHideComponents'], { defaultValue: 'Show or hide components' })}
            />
          </MenuItem>
          <MenuItem onClick={this.handleCheck('showGraph')} className={classes.menuItemStyles}>
            <ListItemIcon>
              <Checkbox
                color="primary"
                checked={cardConf.showGraph}
                onChange={this.handleCheck('showGraph')}
                tabIndex={-1}
                disableRipple
              />
            </ListItemIcon>
            <ListItemText primary={t(['line:showGraph'], { defaultValue: 'Show graph' })} />
          </MenuItem>
          <MenuItem onClick={this.handleCheck('showLineStatus')} className={classes.menuItemStyles}>
            <ListItemIcon>
              <Checkbox
                color="primary"
                checked={cardConf.showLineStatus}
                onChange={this.handleCheck('showLineStatus')}
                tabIndex={-1}
                disableRipple
              />
            </ListItemIcon>
            <ListItemText primary={t(['line:showLineStatus'], { defaultValue: 'Show line status' })} />
          </MenuItem>
          <MenuItem onClick={this.handleCheck('showBatchProgress')} className={classes.menuItemStyles}>
            <ListItemIcon>
              <Checkbox
                color="primary"
                checked={cardConf.showBatchProgress}
                onChange={this.handleCheck('showBatchProgress')}
                tabIndex={-1}
                disableRipple
              />
            </ListItemIcon>
            <ListItemText primary={t(['line:showBatchProgress'], { defaultValue: 'Show batch progress' })} />
          </MenuItem>
          <MenuItem onClick={this.handleCheck('showBatchProgressPercentage')} className={classes.menuItemStyles}>
            <ListItemIcon>
              <Checkbox
                color="primary"
                checked={cardConf.showBatchProgressPercentage}
                onChange={this.handleCheck('showBatchProgressPercentage')}
                tabIndex={-1}
                disableRipple
              />
            </ListItemIcon>
            <ListItemText
              primary={t(['line:showBatchProgressPercentage'], { defaultValue: 'Show batch progress (in percentage)' })}
            />
          </MenuItem>
          <MenuItem onClick={this.handleCheck('showBatchNumber')} className={classes.menuItemStyles}>
            <ListItemIcon>
              <Checkbox
                color="primary"
                checked={cardConf.showBatchNumber}
                onChange={this.handleCheck('showBatchNumber')}
                tabIndex={-1}
                disableRipple
              />
            </ListItemIcon>
            <ListItemText primary={t(['line:showBatchNumber'], { defaultValue: 'Show batch number' })} />
          </MenuItem>
          <MenuItem onClick={this.handleCheck('showBatchProduct')} className={classes.menuItemStyles}>
            <ListItemIcon>
              <Checkbox
                color="primary"
                checked={cardConf.showBatchProduct}
                onChange={this.handleCheck('showBatchProduct')}
                tabIndex={-1}
                disableRipple
              />
            </ListItemIcon>
            <ListItemText primary={t(['line:showBatchProduct'], { defaultValue: 'Show product' })} />
          </MenuItem>
          <MenuItem onClick={this.handleCheck('showBatchProductNumber')} className={classes.menuItemStyles}>
            <ListItemIcon>
              <Checkbox
                color="primary"
                checked={cardConf.showBatchProductNumber}
                onChange={this.handleCheck('showBatchProductNumber')}
                tabIndex={-1}
                disableRipple
              />
            </ListItemIcon>
            <ListItemText primary={t(['line:showBatchProductNumber'], { defaultValue: 'Show product number' })} />
          </MenuItem>
          <MenuItem onClick={this.handleCheck('showEstimatedTimeOfCompletion')} className={classes.menuItemStyles}>
            <ListItemIcon>
              <Checkbox
                color="primary"
                checked={cardConf.showEstimatedTimeOfCompletion}
                onChange={this.handleCheck('showEstimatedTimeOfCompletion')}
                tabIndex={-1}
                disableRipple
              />
            </ListItemIcon>
            <ListItemText
              primary={t(['line:showEstimatedTimeOfCompletion'], { defaultValue: 'Show estimated time of completion' })}
            />
          </MenuItem>
          <MenuItem onClick={this.handleCheck('showStops')} className={classes.menuItemStyles}>
            <ListItemIcon>
              <Checkbox
                color="primary"
                checked={cardConf.showStops}
                onChange={this.handleCheck('showStops')}
                tabIndex={-1}
                disableRipple
              />
            </ListItemIcon>
            <ListItemText primary={t(['shared:showStops'], { defaultValue: 'Show stops on chart' })} />
          </MenuItem>
          <MenuItem onClick={this.handleCheck('showStopLabels')} className={classes.menuItemStyles}>
            <ListItemIcon>
              <Checkbox
                color="primary"
                checked={cardConf.showStopLabels}
                onChange={this.handleCheck('showStopLabels')}
                tabIndex={-1}
                disableRipple
              />
            </ListItemIcon>
            <ListItemText primary={t(['shared:showStopLabels'], { defaultValue: 'Show stop labels' })} />
          </MenuItem>
          <MenuItem onClick={this.handleCheck('countUnregisteredStops')} className={classes.menuItemStyles}>
            <ListItemIcon>
              <Checkbox
                color="primary"
                checked={cardConf.countUnregisteredStops}
                onChange={this.handleCheck('countUnregisteredStops')}
                tabIndex={-1}
                disableRipple
              />
            </ListItemIcon>
            <ListItemText
              primary={t(['shared:countUnregisteredStops'], { defaultValue: 'Show unregistered stops count' })}
            />
          </MenuItem>
          <MenuItem onClick={this.handleCheck('showOee')} className={classes.menuItemStyles}>
            <ListItemIcon>
              <Checkbox
                color="primary"
                checked={cardConf.showOee}
                onChange={this.handleCheck('showOee')}
                tabIndex={-1}
                disableRipple
              />
            </ListItemIcon>
            <ListItemText primary={t(['shared:showOee'], { defaultValue: 'Show OEE' })} />
          </MenuItem>
          <MenuItem onClick={this.handleCheck('unresolvedAndonCalls')} className={classes.menuItemStyles}>
            <ListItemIcon>
              <Checkbox
                color="primary"
                checked={cardConf.unresolvedAndonCalls}
                onChange={this.handleCheck('unresolvedAndonCalls')}
                tabIndex={-1}
                disableRipple
              />
            </ListItemIcon>
            <ListItemText
              primary={t(['andon:showUnresolvedAndonCalls'], { defaultValue: 'Show unresolved Andon calls' })}
            />
          </MenuItem>
          <MenuItem onClick={this.handleCheck('showWeeklyProgress')} className={classes.menuItemStyles}>
            <ListItemIcon>
              <Checkbox
                color="primary"
                checked={cardConf.showWeeklyProgress}
                onChange={this.handleCheck('showWeeklyProgress')}
                tabIndex={-1}
                disableRipple
              />
            </ListItemIcon>
            <ListItemText primary={t(['lines:showWeeklyProgess'], { defaultValue: 'Show weekly progress' })} />
          </MenuItem>
          <MenuItem onClick={this.handleCheck('showDailyProgress')} className={classes.menuItemStyles}>
            <ListItemIcon>
              <Checkbox
                color="primary"
                checked={cardConf.showDailyProgress}
                onChange={this.handleCheck('showDailyProgress')}
                tabIndex={-1}
                disableRipple
              />
            </ListItemIcon>
            <ListItemText primary={t(['lines:showDailyProgess'], { defaultValue: 'Show daily progress' })} />
          </MenuItem>
          <MenuItem onClick={this.handleCheck('showProduced')} className={classes.menuItemStyles}>
            <ListItemIcon>
              <Checkbox
                color="primary"
                checked={cardConf.showProduced}
                onChange={this.handleCheck('showProduced')}
                tabIndex={-1}
                disableRipple
              />
            </ListItemIcon>
            <ListItemText primary={t(['lines:showProduced'], { defaultValue: 'Show produced' })} />
          </MenuItem>
          <MenuItem onClick={this.handleCheck('oneLineCardEachRow')} className={classes.menuItemStyles}>
            <ListItemIcon>
              <Checkbox
                color="primary"
                checked={cardConf.oneLineCardEachRow}
                onChange={this.handleCheck('oneLineCardEachRow')}
                tabIndex={-1}
                disableRipple
              />
            </ListItemIcon>
            <ListItemText primary={t(['shared:oneLineCardEachRow'], { defaultValue: 'Show one Line per row' })} />
          </MenuItem>
          <Divider />
          <MenuItem dense disabled>
            <ListItemText
              classes={{ secondary: classes.menuItemTitle }}
              secondary={t(['line:setTimeInterval'], { defaultValue: 'Set the time interval' })}
            />
          </MenuItem>
          <TimeIntervalMenuItem
            t={t}
            classes={classes}
            interval={0.5}
            cardConf={cardConf}
            handleCheck={this.handleIntervalCheck}
          />
          <TimeIntervalMenuItem
            t={t}
            classes={classes}
            interval={4}
            cardConf={cardConf}
            handleCheck={this.handleIntervalCheck}
          />
          <TimeIntervalMenuItem
            t={t}
            classes={classes}
            interval={8}
            cardConf={cardConf}
            handleCheck={this.handleIntervalCheck}
          />
          <TimeIntervalMenuItem
            t={t}
            classes={classes}
            interval={16}
            cardConf={cardConf}
            handleCheck={this.handleIntervalCheck}
          />
          <TimeIntervalMenuItem
            t={t}
            classes={classes}
            interval={24}
            cardConf={cardConf}
            handleCheck={this.handleIntervalCheck}
          />
          <TimeIntervalMenuItem
            t={t}
            classes={classes}
            interval={48}
            cardConf={cardConf}
            handleCheck={this.handleIntervalCheck}
          />
          <TimeIntervalMenuItem
            t={t}
            classes={classes}
            interval={72}
            cardConf={cardConf}
            handleCheck={this.handleIntervalCheck}
          />
          <Divider />
          <MenuItem dense disabled>
            <ListItemText
              classes={{ secondary: classes.menuItemTitle }}
              secondary={t(['line:setRefreshTimeInterval'], { defaultValue: 'Set the refresh interval' })}
            />
          </MenuItem>
          <RefreshIntervalMenuItem
            label={t(['line:everyMinuteRefreshInterval'], { defaultValue: 'Refresh every minute' })}
            classes={classes}
            interval={(1).minutes}
            cardConf={cardConf}
            handleCheck={this.handleRefreshIntervalCheck}
          />
          <RefreshIntervalMenuItem
            label={t(['line:pluralMinutesRefreshInterval'], {
              defaultValue: 'Refresh every {{minutes}} minutes',
              minutes: 5,
            })}
            classes={classes}
            interval={(5).minutes}
            cardConf={cardConf}
            handleCheck={this.handleRefreshIntervalCheck}
          />
          <RefreshIntervalMenuItem
            label={t(['line:pluralMinutesRefreshInterval'], {
              defaultValue: 'Refresh every {{minutes}} minutes',
              minutes: 10,
            })}
            classes={classes}
            interval={(10).minutes}
            cardConf={cardConf}
            handleCheck={this.handleRefreshIntervalCheck}
          />
          <RefreshIntervalMenuItem
            label={t(['line:noRefreshInterval'], {
              defaultValue: 'No refresh',
            })}
            classes={classes}
            interval={null}
            cardConf={cardConf}
            handleCheck={this.handleRefreshIntervalCheck}
          />
        </Menu>
      </>
    );
  }

  private handleMenuClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ menuAnchorEl: e.currentTarget });
  };

  private handleMenuClose = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.localStorage.setValue(this.props.cardConf);

    // NOTE: The fetch below sets the configuration as a cookie, but we prefer local storage instead.
    // fetch('/config?data=' + encodeURIComponent(JSON.stringify(this.props.cardConf)), { method: 'POST' });
    this.setState({ menuAnchorEl: null });
  };

  private handleCheck = (key: keyof CardConfiguration) => () => {
    const check = this.props.cardConf[key];
    this.props.updateConf(key, !check);
  };

  private handleIntervalCheck = (interval: TimeInterval) => () => {
    this.props.updateIntervalConf(interval);
  };

  private handleRefreshIntervalCheck = (newInterval: number | null) => () => {
    this.props.updateRefreshIntervalConf(newInterval);
  };
}
const enhance = compose<unknown, Properties>(
  withTranslation(['shared', 'line']),
  withStyles(styles),
  withLocalStorage(Constants.LOCAL_STORAGE_LINES_OVERVIEW_LAYOUT_CONFIG, defaultCardConf),
);
export default enhance(ConfigurationMenu as React.ComponentType<unknown>);
