import { ApolloError, useQuery } from '@apollo/client';
import { useLocalStorage } from '@blackbird/ui-base/hooks';
import { Build as MaintenanceIcon } from '@mui/icons-material';
import { Box, Card, CardContent, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { red } from '@mui/material/colors';
import { useTheme } from '@mui/material/styles';
import * as Schema from 'generated/graphql/schema';
import Link from 'next/link';
import { useEffect, useState } from 'react';

import * as Highcharts from '@/components/containers/highcharts';
import SignalAvatar from '@/components/icons/signal-avatar';
import * as Constants from '@/constants';
import { useTimePicker } from '@/contexts/timepicker';
import { lineOverviewWithKPI } from '@/graphql/queries';
import { getYearWeek } from '@/helpers/schedule';
import { useIAM } from '@/hooks';
import { useTranslation } from '@/hooks/use-translation';
import getLineStatusInfo from '@/lib/get-line-status';
import { updateDataHash } from '@/lib/hash';
import manualProcessConfig from '@/lib/highcharts/manual-process-config';
import getConfig from '@/lib/highcharts/overview-config';
import * as Types from '@/types';
import { Module } from '@/types/auth-types';
import LineSettingsDialog from '@/views/dialogs/line-settings/line-settings-dialog';
import { defaultCardConfiguration } from '@/views/lines/line-card-configuration-menu';
import LineOverviewCardMenu from '@/views/lines/line-card-menu';
import LineOverviewInfo from '@/views/lines/overview-components/components';
import { CardConfiguration, TimeInterval } from '@/views/lines/overview-components/menu';

const isMainSensorMissingError = (error: ApolloError | undefined): boolean => {
  // Check if we are getting an error because of a missing main sensor.
  if (
    error?.graphQLErrors?.[0]?.path?.length === 2 &&
    error?.graphQLErrors?.[0]?.path?.[0] === 'line' &&
    error?.graphQLErrors?.[0]?.path?.[1] === 'mainSensor'
  ) {
    return true;
  }

  return false;
};

const calculateTimeInterval = (from: Date): TimeInterval => {
  const halfHours = (new Date().getTime() - from.getTime()) / 1000 / 60 / 30;

  // Round to nearest half hour, as 0.5 is a valid time interval
  return (Math.round(halfHours) / 2) as TimeInterval;
};

const countWorkOrders = (line: Types.DeepPartial<Schema.Line> | undefined) => {
  if (!line?.nodes) {
    return 0;
  }

  return line.nodes.reduce((acc, node) => {
    const workOrders = node?.sensor?.maintenanceWorkOrders?.nodes ?? [];
    if (workOrders.length === 0) {
      return acc;
    }

    if (new Date(workOrders[0]?.timestamp ?? 0) < new Date()) {
      // Work order is overdue
      return acc + 1;
    }

    return acc;
  }, 0);
};

type LineCardProps = {
  lineId: string;
  lineName?: string;
  href: string;
  timeAtLoad: Date;
};

const LineCard: React.FC<LineCardProps> = ({ lineId, lineName, href, timeAtLoad }) => {
  const { t, i18n } = useTranslation(['batches', 'maintenance']);
  const [isSettingsDialogOpen, setIsSettingsDialogOpen] = useState(false);
  const [dataHash, setDataHash] = useState('');
  const [cardConfiguration, setCardConfiguration] = useLocalStorage<CardConfiguration>(
    Constants.LOCAL_STORAGE_LINES_OVERVIEW_LAYOUT_CONFIG,
    defaultCardConfiguration,
  );

  const timeContext = useTimePicker();

  useEffect(() => {
    setCardConfiguration({
      ...cardConfiguration,
      timeInterval: calculateTimeInterval(timeContext.from),
    });
  }, [timeContext.from]);

  // TODO: Move maintenance icon to card config
  const { hasModuleAccess } = useIAM();
  const theme = useTheme();
  const validIn = getYearWeek(timeAtLoad);
  const weekDayNum = (timeAtLoad.getDay() || 7) - 1;
  const mostRecentMinute = Math.round(timeAtLoad.getTime() / (1).minutes) * (1).minutes;
  // Set time to midnight
  const midnight = new Date(mostRecentMinute);
  midnight.setHours(0, 0, 0, 0);

  const variables = {
    lineId,
    time: [
      Types.toISOString({
        from: new Date(mostRecentMinute - cardConfiguration.timeInterval.hours),
        to: timeAtLoad,
      }),
    ],
    points: Constants.SAMPLE_POINTS,
    showStops: cardConfiguration.showStops,
    showOee: cardConfiguration.showOee,
    showWeeklyProgress: Boolean(cardConfiguration.showWeeklyProgress),
    showDailyProgress: Boolean(cardConfiguration.showDailyProgress),
    hasMaintenanceModule: hasModuleAccess(Module.Maintenance),
    validIn,
    pastWeek: [
      Types.toISOString({
        to: timeAtLoad,
        from: new Date(new Date(mostRecentMinute - weekDayNum * (1).days).setHours(0, 0, 0)),
      }),
    ],
    today: [
      Types.toISOString({
        to: timeAtLoad,
        from: midnight,
      }),
    ],
  };

  const { data, error } = useQuery<Schema.LineOverviewWithKpiQuery, Schema.LineOverviewWithKpiQueryVariables>(
    lineOverviewWithKPI,
    {
      variables,
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
      onCompleted: updateDataHash(setDataHash),
    },
  );

  const line = data?.line;

  const workOrderCount = countWorkOrders(line);

  const { status, duration, cause } = line?.mainSensor?.time?.[0]?.stats?.data?.lineStatus || {};
  const stopRegisterThreshold = line?.mainSensor?.config.stopRegisterThreshold ?? (10).seconds;

  const { statusColor, statusText, lineDuration } = getLineStatusInfo(
    t,
    theme,
    status,
    duration ?? 0,
    stopRegisterThreshold,
    cause ?? 'Pending',
  );

  const online =
    (line?.mainSensor?.time?.[0]?.stats?.data?.lineStatus?.status ?? Schema.Status.OFF) !== Schema.Status.OFF;

  return (
    <>
      {isSettingsDialogOpen && line ? (
        <LineSettingsDialog
          lineId={line.id}
          handleOpenClose={() => setIsSettingsDialogOpen(!isSettingsDialogOpen)}
          open={isSettingsDialogOpen}
        />
      ) : null}
      <Link href={href}>
        <Card sx={{ height: '100%', cursor: 'pointer' }}>
          <Grid container alignItems="center" justifyContent="space-between" p={2}>
            <Grid item>
              <Grid container alignItems="center">
                <Grid item>
                  <Constants.LINE_ICON sx={{ height: 20, width: 20, mt: 1, mr: 1, color: statusColor }} />
                </Grid>
                <Typography variant="body1">{line?.name ?? lineName}</Typography>
              </Grid>
              <Typography color="textSecondary" variant="body2" noWrap>
                {line?.description}
              </Typography>
            </Grid>
            <Grid item>
              <Grid container alignItems="center">
                {workOrderCount > 0 && (
                  <Link href="/maintenance">
                    <Tooltip
                      title={t(['maintenance:maintenanceRequired'], {
                        defaultValue: 'Maintenance required for one or more components',
                      })}
                    >
                      <IconButton size="large" sx={{ mr: 1 }}>
                        <MaintenanceIcon sx={{ color: red[700] }} />
                      </IconButton>
                    </Tooltip>
                  </Link>
                )}
                <SignalAvatar online={online} uuid={line?.mainSensor?.peripheralId?.split('-')?.[0] ?? null} />
                <LineOverviewCardMenu
                  lineId={lineId}
                  mainSensor={line?.mainSensor}
                  openDialog={() => setIsSettingsDialogOpen(true)}
                />
              </Grid>
            </Grid>
          </Grid>
          <CardContent>
            <Highcharts.Graph
              compareForUpdate={`${dataHash}-${cardConfiguration.showStops}-${cardConfiguration.showStopLabels}`}
              aggressivelyMinimizeRendering
              configOptions={{
                t,
                language: i18n.language,
                height: '180px',
                sensor: line?.mainSensor,
                theme,
                showStops: cardConfiguration.showStops,
                showStopLabels: cardConfiguration.showStopLabels,
                disableLabels: !cardConfiguration.showStopLabels,
              }}
              hideChart={!cardConfiguration.showGraph}
              configFn={
                line?.mainSensor?.config?.type !== Schema.SensorType.MANUAL_PROCESS ? getConfig : manualProcessConfig
              }
              errorMsg={
                isMainSensorMissingError(error)
                  ? t(['line:noMainSensorConfigured'], { defaultValue: 'No main sensor configured' })
                  : undefined
              }
            />
          </CardContent>
          <Box m={1}>
            {line && (
              <LineOverviewInfo
                lineStatus={{ statusColor, statusText, lineDuration }}
                line={line}
                conf={cardConfiguration}
              />
            )}
          </Box>
        </Card>
      </Link>
    </>
  );
};

export default LineCard;
