import { useQuery } from '@apollo/client';
import { useLocalStorage } from '@blackbird/ui-base/hooks';
import { Grid, LinearProgress, Typography } from '@mui/material';
import * as Schema from 'generated/graphql/schema';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

import Box from '@/components/card-box';
import * as Constants from '@/constants';
import { andonListUnresolvedCallsForLine } from '@/graphql/queries';
import { defaultCardConf } from '@/views/lines/overview-components/menu';

interface Properties {
  lineId: string;
}

const useStyles = makeStyles()((theme) => ({
  background: {
    backgroundColor: `${theme.palette.error.main} !important`,
    color: theme.palette.getContrastText(theme.palette.error.main),
  },
}));

const UnresolvedAndonCalls: FunctionComponent<Properties> = ({ lineId }) => {
  const [t] = useTranslation();
  const [cardConf] = useLocalStorage(Constants.LOCAL_STORAGE_LINES_OVERVIEW_LAYOUT_CONFIG, defaultCardConf);

  const { data, loading } = useQuery<
    Schema.AndonListUnresolvedCallsForLineQuery,
    Schema.AndonListUnresolvedCallsForLineQueryVariables
  >(andonListUnresolvedCallsForLine, {
    ssr: false,
    partialRefetch: true,
    pollInterval: (5).minutes,
    variables: { lineId },
  });

  const calls = data?.andon?.[0]?.calls ?? [];

  const count = loading ? t(['shared:notAvailable'], { defaultValue: 'N/A' }) : calls.length;

  const { classes, cx } = useStyles();

  if (!cardConf.unresolvedAndonCalls) {
    return null;
  }

  // FIXME: react-i18next strikes again with their bad type inference - the cast
  // to any shouldn't be necessary, but it is.
  return (
    <Grid item xs={12} sm={6}>
      <Box small className={cx({ [classes.background]: !!calls.length })}>
        <Typography variant="body2" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {t('andon:unresolvedAndonCalls', { defaultValue: 'Unresolved Andon calls: {{count}}', count } as any)}
        </Typography>
        {loading ? <LinearProgress variant={'indeterminate'} /> : null}
      </Box>
    </Grid>
  );
};

export default UnresolvedAndonCalls;
