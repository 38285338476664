import Link from '@blackbird/ui-base/link';
import { Button, Grid, Tooltip, Typography } from '@mui/material';
import { green } from '@mui/material/colors';
import clsx from 'clsx';
import * as Schema from 'generated/graphql/schema';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

import Box from '@/components/card-box';
import LineStatus from '@/components/line-status';
import Progress from '@/components/progress-meter';
import { useUtils } from '@/hocs';
import { useTranslation } from '@/hooks';
import * as Types from '@/types';
import { CardConfiguration } from '@/views/lines/overview-components/menu';
import Oee from '@/views/lines/overview-components/oee';
import UnresolvedAndonCalls from '@/views/lines/overview-components/unresolved-andon-calls';

const useStyles = makeStyles()((theme) => ({
  gridPad: {
    padding: '0px 16px',
    marginBottom: 8,
    alignItems: 'center',
  },
  oeeGridPad: {
    marginTop: '-7px',
    marginBottom: 0,
  },
  greenButton: {
    backgroundColor: green[600],
    color: theme.palette.getContrastText(green[600]),
    '&:hover': {
      backgroundColor: green[600],
    },
  },
  redButton: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.getContrastText(theme.palette.error.main),
    '&:hover': {
      backgroundColor: theme.palette.error.main,
    },
  },
  small: {
    height: '2em',
    width: '100%',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

interface Properties {
  lineStatus: {
    statusText: string;
    statusColor: string;
    lineDuration: number;
  };

  line: Pick<Schema.Line, 'id'> &
    Types.DeepPartial<Schema.Line> &
    Partial<Pick<Schema.LineOverviewWithKpiQuery['line'], 'todayTime' | 'pastWeekTime'>>;
  conf: CardConfiguration;
}

const LineOverviewInfo: React.FC<Properties> = ({ conf, line, lineStatus }) => {
  const [t] = useTranslation(['shared', 'line', 'batches']);
  const utils = useUtils();
  const { classes } = useStyles();

  const sensorType = line?.mainSensor?.config?.type;
  const batches = line?.mainSensor?.time?.[0]?.batches?.items || [];
  const batchData = batches.reduce<Types.DeepPartial<Schema.Batch> | null | undefined>((agg, item) => {
    if (!agg) {
      return item;
    }

    if (
      !item ||
      (agg.actualStart &&
        item.actualStart &&
        new Date(agg.actualStart).getTime() > new Date(item?.actualStart).getTime())
    ) {
      return agg;
    } else {
      return item;
    }
  }, null);
  const batchProduced = Math.floor(batchData?.stats?.data?.produced ?? 0);
  const product = batchData?.product?.name ?? (t('shared:notAvailable', { defaultValue: 'N/A' }) || '');
  const productNumber = batchData?.product?.itemNumber ?? t('shared:notAvailable', { defaultValue: 'N/A' });
  const completePercentageBatch = batchData ? Math.floor((batchProduced / (batchData?.amount ?? NaN)) * 100) : 0;
  const etc = new Date(
    new Date(Date.now()).getTime() +
      (Math.max((batchData?.amount ?? 0) - batchProduced, 0) / (batchData?.product?.expectedAverageSpeed || 1)) *
        (1).minutes,
  );

  const weeklyTarget = line?.schedule?.weeklyTargets?.produced ?? Infinity;
  const producedPastWeek = line.pastWeekTime?.[0]?.stats?.data?.produced || 0;
  const producedPastDay = line.todayTime?.[0]?.stats?.data?.produced || 0;
  const completePercentageWeek = Math.floor((producedPastWeek / weeklyTarget) * 100);

  const batchNumber = batchData?.batchNumber ?? t('shared:notAvailable', { defaultValue: 'N/A' });
  const unregisteredStops = (line?.mainSensor?.time?.[0]?.stops ?? []).filter((stop) => stop?.registeredTime === null);
  const oee = line?.mainSensor?.time?.[0]?.stats?.data?.oee || null;
  let betterETC;
  if (!batchData) {
    betterETC = t('shared:notAvailable', { defaultValue: 'N/A' });
  } else if (batchData?.state === Schema.BatchState.COMPLETED) {
    betterETC = t('batches:completed', { defaultValue: 'Completed' });
  } else if (etc.getTime() <= Date.now()) {
    betterETC = t('batches:completed', { defaultValue: 'Completed' });
  } else {
    betterETC = utils.formatByString(etc, 'dd MMMM, HH:mm');
  }

  return (
    <Grid container spacing={1}>
      {conf.showBatchProgress ? (
        <Grid item xs={12} sm={6}>
          <Progress
            inlineTitle={t('batches:progress', { defaultValue: 'Batch progress' })}
            completePercentage={completePercentageBatch}
            left={batchData?.amount ? batchProduced : ''}
            right={batchData?.amount || t<string>(['shared:notAvailable'], { defaultValue: 'N/A' })}
            small={true}
          />
        </Grid>
      ) : null}
      {conf.showBatchProgressPercentage ? (
        <Grid item xs={12} sm={6}>
          <Progress
            inlineTitle={t('batches:progress', { defaultValue: 'Batch progress' })}
            completePercentage={completePercentageBatch}
            left={batchData?.amount ? `${completePercentageBatch}%` : ''}
            right={batchData?.amount ? '100%' : t<string>(['shared:notAvailable'], { defaultValue: 'N/A' })}
            small={true}
          />
        </Grid>
      ) : null}
      {conf.showWeeklyProgress ? (
        <Grid item xs={12} sm={6}>
          <Progress
            inlineTitle={t('batches:producedWeek', { defaultValue: 'Produced this week' })}
            completePercentage={completePercentageWeek}
            left={producedPastWeek.toLocaleString()}
            right={
              Number.isFinite(weeklyTarget)
                ? weeklyTarget.toLocaleString()
                : t<string>(['shared:notAvailable'], { defaultValue: 'N/A' })
            }
            small={true}
          />
        </Grid>
      ) : null}
      {conf.showDailyProgress ? (
        <Grid item xs={12} sm={6}>
          <Box small={true}>
            <Typography variant="body2" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              {`${t('shared:producedSinceYesterday', { defaultValue: 'Produced since yesterday' })}: `}
            </Typography>
            <Typography
              variant="body2"
              style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
              noWrap={true}
            >
              {producedPastDay}
            </Typography>
          </Box>
        </Grid>
      ) : null}
      {conf.showLineStatus ? (
        <Grid item xs={12} sm={6}>
          <LineStatus sensorType={sensorType ?? undefined} lineStatus={lineStatus} small={true} />
        </Grid>
      ) : null}
      {conf.showEstimatedTimeOfCompletion ? (
        <Grid item xs={12} sm={6}>
          <Box small={true}>
            <Typography variant="body2" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              {`${t('batches:etcAbbr', { defaultValue: 'ETC' })}: `}
              &nbsp;
            </Typography>
            <Typography
              variant="body2"
              style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
              noWrap={true}
            >
              {betterETC}
            </Typography>
          </Box>
        </Grid>
      ) : null}
      {conf.showBatchNumber ? (
        <Grid item xs={12} sm={6}>
          <Box small={true}>
            <Typography variant="body2" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              {`${t('shared:batchPONumber', { defaultValue: 'Batch/PO No.' })}: `}
              &nbsp;
            </Typography>
            <Typography
              variant="body2"
              style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
              noWrap={true}
            >
              {batchNumber}
            </Typography>
          </Box>
        </Grid>
      ) : null}
      {conf.showBatchProduct ? (
        <Grid item xs={12} sm={6}>
          <Box small={true}>
            <div style={{ display: 'flex', minWidth: 0, padding: '5px' }}>
              <Typography variant="body2" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {`${t('shared:product', { defaultValue: 'Product' })}: `}
                &nbsp;
              </Typography>

              <Tooltip title={product}>
                <Typography
                  style={{
                    flex: 1,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {product}
                </Typography>
              </Tooltip>
            </div>
          </Box>
        </Grid>
      ) : null}
      {conf.showBatchProductNumber ? (
        <Grid item xs={12} sm={6}>
          <Box small={true}>
            <Typography variant="body2" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              {`${t('shared:productNumber', { defaultValue: 'Product No.' })}: `}
              &nbsp;
            </Typography>
            <Typography
              variant="body2"
              style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
              noWrap={true}
            >
              {productNumber}
            </Typography>
          </Box>
        </Grid>
      ) : null}
      {conf.countUnregisteredStops ? (
        <Grid item xs={12} sm={6}>
          <Link
            key={'register-stops'}
            href={{ pathname: '/line', query: { lineId: line.id, tab: 'register-stops' } }}
            underline="none"
          >
            <Button
              color="primary"
              variant="contained"
              className={clsx(unregisteredStops.length ? classes.redButton : classes.greenButton, classes.small)}
            >
              <Typography
                variant="body2"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: '#fff',
                  textTransform: 'none',
                }}
              >
                {`${t('shared:unregisteredStops', { defaultValue: 'Unregistered stops' })}:`}
              </Typography>
              <Typography
                variant="body2"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: '#fff',
                  padding: '5px',
                  fontWeight: 500,
                }}
                noWrap={true}
              >
                {unregisteredStops.length}
              </Typography>
            </Button>
          </Link>
        </Grid>
      ) : null}
      {conf.showOee ? (
        <Grid item xs={12} sm={6} className={clsx(classes.gridPad, classes.oeeGridPad)}>
          <Oee oee={oee} />
        </Grid>
      ) : null}
      {conf.showProduced ? (
        <Grid item xs={12} sm={6}>
          <Box small={true}>
            <div style={{ display: 'flex', minWidth: 0, padding: '5px' }}>
              <Typography variant="body2" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {`${t('shared:produced', { defaultValue: 'Produced' })}: ${
                  line.mainSensor?.time?.[0]?.stats?.data?.produced ?? 0
                } ${line.mainSensor?.config?.sensorUnit?.label ?? ''}`}
              </Typography>
            </div>
          </Box>
        </Grid>
      ) : null}
      <UnresolvedAndonCalls lineId={line.id} />
    </Grid>
  );
};

export default LineOverviewInfo;
