import { useLocalStorage } from '@blackbird/ui-base/hooks';
import { Checkbox, Divider, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Tooltip } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { ViewQuilt } from 'mdi-material-ui';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

import CountdownButton from '@/components/buttons/countdown-button';
import * as Constants from '@/constants';
import { useTranslation } from '@/hooks/use-translation';
import { CardConfiguration } from '@/views/lines/overview-components/menu';

const useStyles = makeStyles()((theme: Theme) => ({
  menuItemTitle: {
    color: theme.palette.text.primary,
  },
  menuItemStyles: {
    padding: theme.spacing(0),
  },
}));

export const defaultCardConfiguration: CardConfiguration = {
  showGraph: true,
  showLineStatus: false,
  showBatchProgress: false,
  showBatchProgressPercentage: false,
  showBatchProduct: false,
  showBatchProductNumber: false,
  showEstimatedTimeOfCompletion: false,
  showBatchNumber: false,
  showProduced: false,
  timeInterval: 8,
  refreshInterval: (5).minutes,
  showStops: false,
  showStopLabels: false,
  showOee: false,
  countUnregisteredStops: false,
  oneLineCardEachRow: false,
  unresolvedAndonCalls: false,
  showWeeklyProgress: false,
  showDailyProgress: false,
};

type RefreshIntervalMenuItemProperties = {
  label: string;
  checked: boolean;
  onClick: () => void;
};

const RefreshIntervalMenuItem = (props: RefreshIntervalMenuItemProperties) => {
  const { label, checked, onClick } = props;

  const { classes } = useStyles();

  return (
    <MenuItem onClick={onClick} className={classes.menuItemStyles}>
      <ListItemIcon>
        <Checkbox color="primary" checked={checked} onChange={onClick} tabIndex={-1} disableRipple />
      </ListItemIcon>
      <ListItemText primary={label} />
    </MenuItem>
  );
};

type ConfigurationMenuProperties = {
  timeAtLoad: Date;
};

const LineOverviewMenu: React.FC<ConfigurationMenuProperties> = ({ timeAtLoad }) => {
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<HTMLElement | null>(null);
  const open = Boolean(menuAnchorEl);
  const [cardConfiguration, setCardConfiguration] = useLocalStorage<CardConfiguration>(
    Constants.LOCAL_STORAGE_LINES_OVERVIEW_LAYOUT_CONFIG,
    defaultCardConfiguration,
  );

  const nextLiveUpdate = new Date(timeAtLoad.getTime() + (cardConfiguration.refreshInterval ?? 0));

  const { t } = useTranslation(['line', 'shared']);
  const { classes } = useStyles();

  const handleCheck = (key: keyof CardConfiguration) => () => {
    setCardConfiguration({ ...cardConfiguration, [key]: !cardConfiguration[key] });
  };

  return (
    <>
      <CountdownButton updateInterval={cardConfiguration.refreshInterval} nextLiveUpdate={nextLiveUpdate}>
        <Tooltip
          title={t(['shared:configurePersonalOverview'], { defaultValue: 'Configure your personal overview' })}
          placement={'left-start'}
        >
          <IconButton
            aria-label="More"
            aria-owns={false ? 'long-menu' : undefined}
            aria-haspopup="true"
            onClick={(e) => setMenuAnchorEl(e.currentTarget)}
            size="large"
          >
            <ViewQuilt />
          </IconButton>
        </Tooltip>
      </CountdownButton>
      <Menu
        id="configuration-menu"
        anchorEl={menuAnchorEl}
        open={open}
        keepMounted
        onClose={() => setMenuAnchorEl(null)}
      >
        <MenuItem dense disabled>
          <ListItemText
            classes={{ secondary: classes.menuItemTitle }}
            secondary={t(['line:showOrHideComponents'], { defaultValue: 'Show or hide components' })}
          />
        </MenuItem>
        <MenuItem onClick={handleCheck('showGraph')} className={classes.menuItemStyles}>
          <ListItemIcon>
            <Checkbox
              color="primary"
              checked={cardConfiguration.showGraph}
              onChange={handleCheck('showGraph')}
              tabIndex={-1}
              disableRipple
            />
          </ListItemIcon>
          <ListItemText primary={t(['line:showGraph'], { defaultValue: 'Show graph' })} />
        </MenuItem>
        <MenuItem onClick={handleCheck('showLineStatus')} className={classes.menuItemStyles}>
          <ListItemIcon>
            <Checkbox
              color="primary"
              checked={cardConfiguration.showLineStatus}
              onChange={handleCheck('showLineStatus')}
              tabIndex={-1}
              disableRipple
            />
          </ListItemIcon>
          <ListItemText primary={t(['line:showLineStatus'], { defaultValue: 'Show line status' })} />
        </MenuItem>
        <MenuItem onClick={handleCheck('showBatchProgress')} className={classes.menuItemStyles}>
          <ListItemIcon>
            <Checkbox
              color="primary"
              checked={cardConfiguration.showBatchProgress}
              onChange={handleCheck('showBatchProgress')}
              tabIndex={-1}
              disableRipple
            />
          </ListItemIcon>
          <ListItemText primary={t(['line:showBatchProgress'], { defaultValue: 'Show batch progress' })} />
        </MenuItem>
        <MenuItem onClick={handleCheck('showBatchProgressPercentage')} className={classes.menuItemStyles}>
          <ListItemIcon>
            <Checkbox
              color="primary"
              checked={cardConfiguration.showBatchProgressPercentage}
              onChange={handleCheck('showBatchProgressPercentage')}
              tabIndex={-1}
              disableRipple
            />
          </ListItemIcon>
          <ListItemText
            primary={t(['line:showBatchProgressPercentage'], { defaultValue: 'Show batch progress (in percentage)' })}
          />
        </MenuItem>
        <MenuItem onClick={handleCheck('showBatchNumber')} className={classes.menuItemStyles}>
          <ListItemIcon>
            <Checkbox
              color="primary"
              checked={cardConfiguration.showBatchNumber}
              onChange={handleCheck('showBatchNumber')}
              tabIndex={-1}
              disableRipple
            />
          </ListItemIcon>
          <ListItemText primary={t(['line:showBatchNumber'], { defaultValue: 'Show batch number' })} />
        </MenuItem>
        <MenuItem onClick={handleCheck('showBatchProduct')} className={classes.menuItemStyles}>
          <ListItemIcon>
            <Checkbox
              color="primary"
              checked={cardConfiguration.showBatchProduct}
              onChange={handleCheck('showBatchProduct')}
              tabIndex={-1}
              disableRipple
            />
          </ListItemIcon>
          <ListItemText primary={t(['line:showBatchProduct'], { defaultValue: 'Show product' })} />
        </MenuItem>
        <MenuItem onClick={handleCheck('showBatchProductNumber')} className={classes.menuItemStyles}>
          <ListItemIcon>
            <Checkbox
              color="primary"
              checked={cardConfiguration.showBatchProductNumber}
              onChange={handleCheck('showBatchProductNumber')}
              tabIndex={-1}
              disableRipple
            />
          </ListItemIcon>
          <ListItemText primary={t(['line:showBatchProductNumber'], { defaultValue: 'Show product number' })} />
        </MenuItem>
        <MenuItem onClick={handleCheck('showEstimatedTimeOfCompletion')} className={classes.menuItemStyles}>
          <ListItemIcon>
            <Checkbox
              color="primary"
              checked={cardConfiguration.showEstimatedTimeOfCompletion}
              onChange={handleCheck('showEstimatedTimeOfCompletion')}
              tabIndex={-1}
              disableRipple
            />
          </ListItemIcon>
          <ListItemText
            primary={t(['line:showEstimatedTimeOfCompletion'], { defaultValue: 'Show estimated time of completion' })}
          />
        </MenuItem>
        <MenuItem onClick={handleCheck('showStops')} className={classes.menuItemStyles}>
          <ListItemIcon>
            <Checkbox
              color="primary"
              checked={cardConfiguration.showStops}
              onChange={handleCheck('showStops')}
              tabIndex={-1}
              disableRipple
            />
          </ListItemIcon>
          <ListItemText primary={t(['shared:showStops'], { defaultValue: 'Show stops on chart' })} />
        </MenuItem>
        <MenuItem onClick={handleCheck('showStopLabels')} className={classes.menuItemStyles}>
          <ListItemIcon>
            <Checkbox
              color="primary"
              checked={cardConfiguration.showStopLabels}
              onChange={handleCheck('showStopLabels')}
              tabIndex={-1}
              disableRipple
            />
          </ListItemIcon>
          <ListItemText primary={t(['shared:showStopLabels'], { defaultValue: 'Show stop labels' })} />
        </MenuItem>
        <MenuItem onClick={handleCheck('countUnregisteredStops')} className={classes.menuItemStyles}>
          <ListItemIcon>
            <Checkbox
              color="primary"
              checked={cardConfiguration.countUnregisteredStops}
              onChange={handleCheck('countUnregisteredStops')}
              tabIndex={-1}
              disableRipple
            />
          </ListItemIcon>
          <ListItemText
            primary={t(['shared:countUnregisteredStops'], { defaultValue: 'Show unregistered stops count' })}
          />
        </MenuItem>
        <MenuItem onClick={handleCheck('showOee')} className={classes.menuItemStyles}>
          <ListItemIcon>
            <Checkbox
              color="primary"
              checked={cardConfiguration.showOee}
              onChange={handleCheck('showOee')}
              tabIndex={-1}
              disableRipple
            />
          </ListItemIcon>
          <ListItemText primary={t(['shared:showOee'], { defaultValue: 'Show OEE' })} />
        </MenuItem>
        <MenuItem onClick={handleCheck('unresolvedAndonCalls')} className={classes.menuItemStyles}>
          <ListItemIcon>
            <Checkbox
              color="primary"
              checked={cardConfiguration.unresolvedAndonCalls}
              onChange={handleCheck('unresolvedAndonCalls')}
              tabIndex={-1}
              disableRipple
            />
          </ListItemIcon>
          <ListItemText
            primary={t(['andon:showUnresolvedAndonCalls'], { defaultValue: 'Show unresolved Andon calls' })}
          />
        </MenuItem>
        <MenuItem onClick={handleCheck('showWeeklyProgress')} className={classes.menuItemStyles}>
          <ListItemIcon>
            <Checkbox
              color="primary"
              checked={cardConfiguration.showWeeklyProgress}
              onChange={handleCheck('showWeeklyProgress')}
              tabIndex={-1}
              disableRipple
            />
          </ListItemIcon>
          <ListItemText primary={t(['lines:showWeeklyProgess'], { defaultValue: 'Show weekly progress' })} />
        </MenuItem>
        <MenuItem onClick={handleCheck('showDailyProgress')} className={classes.menuItemStyles}>
          <ListItemIcon>
            <Checkbox
              color="primary"
              checked={cardConfiguration.showDailyProgress}
              onChange={handleCheck('showDailyProgress')}
              tabIndex={-1}
              disableRipple
            />
          </ListItemIcon>
          <ListItemText primary={t(['lines:showDailyProgess'], { defaultValue: 'Show daily progress' })} />
        </MenuItem>
        <MenuItem onClick={handleCheck('oneLineCardEachRow')} className={classes.menuItemStyles}>
          <ListItemIcon>
            <Checkbox
              color="primary"
              checked={cardConfiguration.oneLineCardEachRow}
              onChange={handleCheck('oneLineCardEachRow')}
              tabIndex={-1}
              disableRipple
            />
          </ListItemIcon>
          <ListItemText primary={t(['shared:oneLineCardEachRow'], { defaultValue: 'Show one Line per row' })} />
        </MenuItem>
        <Divider />
        <MenuItem dense disabled>
          <ListItemText
            classes={{ secondary: classes.menuItemTitle }}
            secondary={t(['line:setRefreshTimeInterval'], { defaultValue: 'Set the refresh interval' })}
          />
        </MenuItem>
        <RefreshIntervalMenuItem
          label={t(['line:everyMinuteRefreshInterval'], { defaultValue: 'Refresh every minute' })}
          checked={cardConfiguration.refreshInterval === (1).minutes}
          onClick={() => setCardConfiguration({ ...cardConfiguration, refreshInterval: (1).minutes })}
        />
        <RefreshIntervalMenuItem
          label={t(['line:pluralMinutesRefreshInterval'], {
            defaultValue: 'Refresh every {{minutes}} minutes',
            minutes: 5,
          })}
          checked={cardConfiguration.refreshInterval === (5).minutes}
          onClick={() => setCardConfiguration({ ...cardConfiguration, refreshInterval: (5).minutes })}
        />
        <RefreshIntervalMenuItem
          label={t(['line:pluralMinutesRefreshInterval'], {
            defaultValue: 'Refresh every {{minutes}} minutes',
            minutes: 10,
          })}
          checked={cardConfiguration.refreshInterval === (10).minutes}
          onClick={() => setCardConfiguration({ ...cardConfiguration, refreshInterval: (10).minutes })}
        />
        <RefreshIntervalMenuItem
          label={t(['line:noRefreshInterval'], {
            defaultValue: 'No refresh',
          })}
          checked={!Boolean(cardConfiguration.refreshInterval)}
          onClick={() => setCardConfiguration({ ...cardConfiguration, refreshInterval: null })}
        />
      </Menu>
    </>
  );
};

export default LineOverviewMenu;
