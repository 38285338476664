import { useContext } from 'react';

import { LineContext } from '@/contexts/line';

export interface ContextLine {
  lineId: string | null;
}

export const useLine = (): ContextLine => {
  const { lineId } = useContext(LineContext);
  return {
    lineId: lineId || null,
  };
};
